:root
  --primary-color: #D2C477
  --color-primary: #D2C477
  --primary-r: 210
  --primary-g: 196
  --primary-b: 119
  --primary-h: 51
  --primary-s: 50.3%
  --primary-l: 64.5%
  --switch: 100%
:root
  --secondary-color: #000000
  --color-secondary: #000000
